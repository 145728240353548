.connection-test-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.connection-test-block {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.test-block-name {
    margin-right: 20px !important;
}

.connection-test-result {
    display: flex;
    flex-direction: column;
    align-items: center;
}