@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Asap:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alatsi&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Girassol&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Aleo&display=swap);
.event-link{
    color: blue;
}

.event-link:hover{
    text-decoration: underline;
}

.disabled{
    pointer-events: none !important;
    opacity: .3;
}

.leaderboard{
    padding-top: 80px;
    padding-bottom: 50px;
}

.lead-title{
    font-size: 34px;
    margin-bottom: 15px;
}

#form-dialog-title h2{
    font-size: 22px !important;
    text-align: center;
}

.team-form-title{
    font-size: 20px;
    font-weight: 400;
    margin-top: 20px;
    text-align: center;
}

.member-input{
    margin-top: 7px;
    margin-bottom: 7px;
}

.member-input:not(.notes){
    width: 50%;
}

.member-input input,
.member-input textarea{
    border-radius: 0 !important;
    text-align: left;
}

.member-input input::-webkit-input-placeholder, .member-input textarea::-webkit-input-placeholder{
    font-weight: 400;
}

.member-input input:-ms-input-placeholder, .member-input textarea:-ms-input-placeholder{
    font-weight: 400;
}

.member-input input::-ms-input-placeholder, .member-input textarea::-ms-input-placeholder{
    font-weight: 400;
}

.member-input input::placeholder,
.member-input textarea::placeholder{
    font-weight: 400;
}

.team-form-link{
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 15px;
}

.members-list{
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 0 auto;
    min-width: 65px;
}

.members-list li{
    margin-bottom: 3px;
    text-align: left;
}

.cancel-btn{
    margin-right: auto !important;
}

.mail-event-name{
    color:#333;
    font-size: 30px;
    font-weight: 500;
}

.mail-time * {
    font-size: 16px !important;
    margin-bottom: 15px;
}

.mail-team{
    font-size: 26px;
    color:#333;
    font-weight: 500;
}

.mail-title{
    font-size: 16px;
    color:#333;
    font-weight: 500;
    margin-top: 15px;
}

.table-title.coord-title{
    padding-bottom: 0 !important;  
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.table-title.coord-title button {
    margin-left: 30px;
}

.team-list {
    margin-right: 0px !important;
}

.rate-top[data-ranked="false"]{
    height: 36px;
}

.upload-team-body {
    display: flex;
    flex-direction: column;
}

.upload-team-input {
    align-self: center;
    min-width: 300px;
    max-width: 300px;
}

.upload-errors-list, .upload-duplicate-teams-list {
    margin: 20px 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 800px;
}

.upload-errors-title, .upload-duplicate-teams-title {
    align-self: center;
    margin-bottom: 10px;
    font-size: 20px;
}

.upload-errors-item {
    display: flex;
    align-items: center;
    max-width: 800px;
    margin-bottom: 10px;
}

.upload-errors-item-name {
    margin-left: 10px;
    color: #556cd6;
}

.upload-errors-item-separator {
    margin: 0 10px;
}

.upload-duplicate-team-names {
    color: #556cd6;
    font-size: 16px;
}

.download-XSV-button {
    display: flex;
    align-items: center;
}

.download-teams-btn {
    width: 170px;
}

.download-XSV-blob-link-hidden {
    visibility: hidden;
}

@media (max-width:500px){
    .member-input{
        width: 100% !important;
    }
    
    .add-team-body{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    
    .team-form-link{
        font-size: 12px;
    }
    
    .MuiDialog-paper {
        margin: 10px !important;
    }
    
    .MuiDialog-paperFullWidth{
        width: calc(100% - 20px) !important;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100% - 20px) !important;
    }
    
    .mail-link{
        font-size: 12px;
    }

    .table-title.coord-title {
        margin: 0 auto;
        flex-direction: column;
    }
    .table-title.coord-title button {
        margin: 0px;
        margin-bottom: 10px;
    }
}
.game-info{
    background: #333;
}

.event-name{
    font-weight: 500 !important;
    color:#333;
}

.team-word{
    font-weight: 400 !important;
    color:#666;
}

.team-value{
    font-weight: 400 !important;
    color:#666;
}

.progress{
    font-size: 18px;
    margin-left: 10px !important;
    color:#666;
    text-align: right;
}

.coord-head.game-all-info .game-time-text{
    font-size: 30px;
    font-weight: 500;
    color:#333;
    line-height: 1;
    margin-bottom: 0px;
}

.coord-head.game-all-info .game-time-value{
    font-size: 16px;
    font-weight: 400;
    color:#333;
}

.coord-head.game-all-info .game-time-value span{
    font-size: 32px;
    font-weight: 500;
    margin-left: 5px;
    display: inline-block;
}

.coord-head .game-time-info{
    display: flex;
    justify-content: center;
    width: 450px;
}

.team-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.event-info{
    width: 600px !important;
}

.time-to-wrap{
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.time-to{
    font-size: 18px !important;
    font-weight: 400 !important;
}

.teams-info{
    font-size: 18px;
    margin-left: 20px;
    font-weight: 400;
}

.resch-btn{
    max-width: 110px;
}

.datetime-wrap-hidden{
    position: absolute;
    visibility: hidden;
}

.picker-wrap{
    position: relative;
}

.start-wrap {
    margin-left: 20px;
}

.picker-error{
    position: absolute;
    left:0%;
    top:120%;
    color:#f00;
    width: 500px;
    text-align: left;
}

.game-countdown2{
    font-size: 18px !important;
    font-weight: 300;
}

.game-countdown2 > span{
    font-size: 30px !important;
    margin-left: 4px !important;
    font-weight: 400 !important;
}

@media(max-width:1050px){
    .coord-head .time-to-wrap{
        width: 100%;
        margin-bottom: 10px;
    }

    .coord-head .team-info{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .coord-head .game-time-info{
        width: 400px !important;
    }

    .coord-head .event-info{
        width: auto !important;
        flex:1 1;
    }
}

@media(max-width:992px){
    .game-time-info{
        width: 320px;
    }

    .time-to{
        font-size: 22px;
    }
}

@media(max-width:850px){
    .team-info{
        flex-wrap:wrap;
    }

    .event-info{
        width: 350px;
    }

    .team-word,
    .team-value{
        font-size: 21px !important;
    }

    .event-name{
        font-size: 31px !important;
    }
}

@media(max-width:768px){
    .game-all-info{
        margin-top: 0 !important;
        margin-bottom: 40px !important;
    }

    .progress{
        width: 100%;
        text-align: left !important;
        margin-left: 0 !important;
        margin-top: 2px;
    }

    .time-to{
        font-size: 20px;
    }

    .coord-head.game-all-info{
        flex-wrap: wrap;
    }

    .coord-head .event-info{
        width: 100%;
        flex:auto;
        margin-bottom: 25px;
    }

    .coord-head .game-time-info{
        width: 100% !important;
    }

    .table-wrap.coord-wrap{
        padding: 20px 0 !important;
    }

    .coord-head .game-time{
        margin-top: 20px;
    }
}

@media(max-width:675px){
    .game-time-value{
        font-size: 14px;
        font-weight: 400;
        color:#333;
    }
    
    .game-time-value span{
        font-size: 24px;
        font-weight: 500;
        margin-left: 5px;
        display: inline-block;
    }

    .game-time-text{
        font-size: 26px;
    }

    .event-name{
        font-size: 30px !important;
    }
    
    .team-word{
        font-size: 21px !important;
    }
    
    .team-value{
        font-size: 21px !important;

    }
    
    .progress{
        font-size: 16px;
    }

    .time-to-wrap{
        min-width: 100%;
    }

    .time-to{
        font-size: 20px;
    }
}

@media(max-width:575px){
    .event-info{
        width: 100%;
    }

    .game-time-info{
        width: 100%;
        justify-content: flex-start;
    }

    .game-all-info{
        flex-wrap: wrap;
    }

    .coord-head .game-time{
        align-items: flex-start !important;
        margin-top: 3px;
    }

    .coord-head.game-all-info{
        margin-bottom: 0px !important;
    }

    .coord-head .game-time-info{
        justify-content: flex-start;
    }

    .team-title{
        margin-right: 20px;
    }

    .coord-head .time-to-wrap{
        margin-top: 5px;
        margin-bottom: 10px;
    }
}

@media(max-width:475px){
    .game-countdown2{
        font-size: 12px !important;
    }

    .game-countdown2 span{
        font-size: 22px !important;
    }
}
.error-wrap{
    min-height: 100vh;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error-icon svg{
    font-size: 100px;
}

.error-title{
    font-size: 50px;
    line-height: 1.1 !important;
    text-align: center;
    margin-bottom: 15px;
}

.error-text{
    font-size: 22px;
    color:#666;
    text-align: center !important
}

@media(max-width:500px){
    .error-title{
        font-size: 36px !important;
    }
    
    .error-text{
        font-size: 18px !important;
    }
}
.table-paper{
    width: 100%;
    padding: 0 25px;
    box-shadow: none;
}

.table-wrap{
    padding-top: 40px;
    padding-bottom: 40px;
}

.table-title{
    margin-right: auto;
}

.admin-table{
    min-width: 750px;
}

.admin-table-controls{
    cursor: pointer;
}

.add-event-btn{
    min-height: 40px;
    display: block;
    margin-left: 20px !important;
}

.description{
    max-width: 250px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.MuiTooltip-arrow{
    color: #333 !important;
}

.MuiTooltip-tooltip{
    background: #333 !important;
    font-size: 14px !important;
}

.sort-select{
    min-width: 125px;
    padding-left: 8px;
}

.MuiSelect-select{
    background: #fff !important;
    text-align: center;
}

.form-control{
    width: 100%;
}

.toolbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.app-bar{
    position: relative !important;
}

.add-event-form{
    padding-top: 25px;
    padding-bottom: 25px;
    max-width: 992px !important;
}

.form-wrap > div{
    margin: -10px;
    margin-bottom: 10px;
}

.form-wrap > div > div{
    width: 33.3333%;
    padding: 10px;
}

.MuiTextField-root,
.MuiInputBase-formControl{
    width: 100%;
}

.datetime-wrap{
    display: flex;
    align-items: flex-start;
}

.textarea-wrap{
    margin: 0 !important;
    margin-bottom: 15px !important;
}

.textarea-input{
    width: 100% !important;
    padding: 0 !important;
}

.textarea-input textarea{
    text-align: left !important;
    border-radius: 0 !important;
}

.disabled{
    pointer-events: none !important;
    opacity: .3;
}

.action-btns{
    display: flex;
    padding: 0 10px;
    margin-top: 0px !important;
}

.action-btns > *{
    flex:1 1;
}

.action-btns > *:first-child{
    margin-right: 10px;
}

.admin-top-panel{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 25px;
}

.admin-top-title{
    font-size: 28px;
    font-weight: 500;
}

@media(max-width:768px){
    .form-wrap > div{
        flex-wrap:wrap;
    }

    .form-wrap > div > div{
        width: 50%;
        padding: 10px;
    }
}

@media(max-width:500px){
    .table-title{
        width: 100%;
        padding-bottom: 10px;
    }

    .admin-top-wrap{
        display: flex;
        flex-wrap: wrap;
        padding-top: 20px;
    }

    .sort-select{
        margin-right: auto;
    }

    .table-paper{
        padding: 0 13px !important;
    }

    .table-wrap{
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .admin-table-head{
        line-height: 1.1 !important;
        white-space: nowrap;
    }

    .form-wrap > div > div{
        width: 100%;
        padding: 10px;
    }
}
.avatar{
    background-color: #666 !important;
    margin: 8px;
}

.auth-form{
    width: 100%;
    margin: 24px;
}

.auth-btn{
    min-height: 55px;
    margin-top: 12px !important;
    display: block;
}
.avatar{
    background-color: #666 !important;
    margin: 8px;
}

.auth-form{
    width: 100%;
    margin: 24px;
}

.auth-btn{
    min-height: 55px;
    margin-top: 12px !important;
    display: block;
}
.empty {
    min-width: 200px
}

.top-game-img-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.top-game-img {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    width: 400px;
}

.team-login-wrapper {
    margin-top: 30px;
}

.team-login-content-body {
    font-weight: 400;
    font-size: 18px;
    padding: 15px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-login-content-body {
    height: 300px;
}

.team-login-title {
    margin-top: 20px;
    margin-bottom: 30px;
}

.team-login-enter-code-block {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 400px;
}

.team-login-enter-code-input {
    width: 200px !important;
}

.check-code-btn {
    margin-top: 10px !important;
    margin-left: 20px !important;
}
.footer-block{
    background: #333;
}

.footer-text{
    color:#fff;
}

a.footer-link:link{
    color: #f26822;
}

a.footer-link:visited{
    color: #f26822;
}

.footer-logo{
    max-height: 30px;
    height: auto;
}

.monitoring-table-wrap,
.monitoring-table-paper {
    padding: 0px !important;
}

.monitoring-table-paper {
    padding: 20px 30px !important;
}

.monitoring-table {
    margin-bottom: 30px;
}
.monitoring-rates-row:last-of-type td {
    border-bottom: none;
}

.monitoring-table-toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}
.monitoring-table-toolbar div {
    margin: 0 10px;
}

.monitoring-table-head {
    line-height: 1.1 !important;
    white-space: nowrap;
}

.monitoring-table-head-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.background-image{
    position: fixed;
    left:0;
    top:0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}

.background-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-container{
    padding-top: 265px;
    padding-bottom: 80px;
}

@media(max-width:750px){
    .content-container{
        padding-top: 200px;
    }
}

@media(max-width:575px){
    .content-container{
        padding-top: 245px;
    }
}

.header{
    position: fixed;
    left:0;
    right:0;
    top:0;
    height: 230px;
    background: #fff;
    z-index: 2;
    padding:10px;
    box-shadow: 0 7px 14px rgba(0,0,0,.05);
}

.container-big{
    max-width: 1650px;
    margin: 0 auto;
    padding: 0 !important;
}

.waiting-header{
    margin-bottom: 60px;
    padding: 10px 0;
}

@media(max-width:750px){
    .header{
        height: 163px;
    }
}

@media(max-width:675px){
    .header{
        height: 160px;
    }
}

@media(max-width:575px){
    .header{
        height: 235px;
    }

    .waiting-header{
        margin-bottom: 10px;
    }
}

#confetti {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    z-index: 3;
}
.rates-block{
    min-width: 375px;
}

.rates-item{
    border-radius: 7px;
    overflow: hidden;
    background: #fff;
    max-width: 150px;
    min-width: 150px;
}

.rates-item-title{
    font-size: 13px;
    padding: 6px 8px;
    border-bottom: 1px solid #ccc;
    background: #333;
    color:#000;
    font-weight: 500;
}

.rates-item-body{
    padding: 6px 8px;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rate-top{
    background: #333;
    color:#000
}

.rate-popup-item{
    border-radius: 7px !important;
    overflow: hidden;
}

.rate-top{
    font-size: 15px;
    font-weight: 500;
}

.rate-line{
    display: flex;
    align-items: center;
}

.rate-line span{
    font-weight: 600;
    display: inline-block;
    margin-left: 3px;
}

.star-icon{
    width: 15px !important;
    height: 15px !important;
    margin-right: 2px;
    margin-top: -2px;
}

.rate-icon{
    margin-right: 8px;
}

.mobile-rates{
    display: none !important;
}

.top-rate-title{
    line-height: 1 !important;
}

.top-rate-block{
    margin-bottom: 10px;
}

.rates-item-wrap{
    padding-top: 11px;
    padding-bottom: 11px;
}

.rates-item-title[data-ranked="false"]{
    height: 30px;
}

.header-background-1{
    position: absolute;
    left:50%;
    bottom:0;
    transform: translateX(-50%);
    width: 100vw;
    z-index: -1;
    height: 88px;
}

.header-background-1 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media(max-width:850px){
    .rates-block{
        min-width: 350px;
    }
}

@media(max-width:750px){
    .rates-block > *:not(.mobile-rates){
        display: none;
    }

    .mobile-rates{
        display: block !important;
    }

    .rates-block{
        min-width: 120px
    }
}

.your-team-popup #customized-dialog-title,
.event-coordinator-popup #customized-dialog-title,
.how-to-play-popup #customized-dialog-title {
  background-color: #faa918;
}

.your-team-popup #customized-dialog-title h2,
.event-coordinator-popup #customized-dialog-title h2,
.how-to-play-popup #customized-dialog-title h2 {
  font-family: Alatsi;
  font-size: 2rem;
}

.your-team-popup .team-member span,
.event-coordinator-popup .coordinator-data span {
  font-family: Aleo !important;
}

.how-to-play-popup .subtitle {
  font-family: Girassol;
  font-size: 2rem;
}

.how-to-play-popup .subtitle-note {
  font-family: Girassol;
  font-size: 1rem;
}

.how-to-play-popup .text {
  margin: 1em 0;
  font-family: Aleo;
}

.top-image{
    width: 100%;
    max-height: 66px;
    text-align: center;
    margin-top: 11px;
    margin-bottom: 11px;
}

.top-image img{
    display: inline-block;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.left-btn{
    margin-right: 8px !important;
}

.coordinator-icon{
    margin-right: 24px
}

.dialog-content{
    padding: 0 24px;
}

.nav-btns{
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}

.nav-content{
    display: flex;
    flex-direction:column;
    align-items:flex-end;
    width: 400px;
}

.top-header-nav-block{
  position: relative;
}

.top-header-nav-block > *:not(.header-waiting-white-background):not(.header-waiting-background-1){
  z-index: 2;
}

.header-waiting-background-1{
  width: 100vw;
  position: absolute;
  z-index: 1;
  left:50%;
  bottom:0;
  height: 88px;
  transform: translateX(-50%);
  display: none;
}

.header-waiting-background-1 img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-waiting-white-background{
  width: 100vw;
  position: absolute;
  z-index: 0;
  background: #fff;
  left:50%;
  bottom:0;
  top:-20px;
  transform: translateX(-50%);
  display: none;
}

.waiting-header .header-waiting-background-1,
.waiting-header .header-waiting-white-background{
  display: block;
}

@media(max-width:992px){
    .nav-btns > *:not(.ham){
        display: none;
    }
    
    .nav-btns{
        justify-content: flex-end;
    }

    .nav-content{
        width: 320px;
    }
}

@media(max-width:750px){
  .top-image{
    display: none;
  }

  .header-waiting-background-1{
    display: none !important;
  }
}









/* Mobile Nav */

.mobile-nav {
    height: 100vh;
    width: 320px;
    position: fixed;
    right: -350px;
    top: 0;
    padding: 30px;
    padding-top: 60px;
    background: #fff;
    z-index: 100 !important;
    transition: .3s;
    box-shadow: 0 7px 14px rgba(0, 0, 0, .3);
    display: flex;
    flex-direction: column;
  }
  
  .mobile-nav.active {
    right: 0;
  }
  
  .mobile-nav ul{
    display: flex;
    flex-direction: column;
  }
  
  .mobile-nav ul li {
    margin-bottom: 20px;
    margin-left: 0 !important;
  }
  
  .mobile-nav ul li div {
    font-size: 22px;
    font-weight: 400;
    cursor: pointer;
  }
  
  .mobile-nav ul li div.active{
    color:#999;
    padding-bottom: 2px;
  }
  
  .mobile-nav .close {
    position: absolute;
    top: 20px;
    right: 25px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background: url(/static/media/my-times.cbe4567e.svg) no-repeat center;
    background-size: contain;
  }
  
  .ham {
    display: none !important;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background: url(/static/media/my-ham.5f6c09fd.svg) no-repeat center;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #556cd6;
  }
  
  .ham img {
    -webkit-filter: invert(100%);
            filter: invert(100%);
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
  
  @media (max-width:992px) {
    .ham {
      display: flex !important;
    }
  }
  
  @media (max-width:500px) {
    .mobile-nav {
      width: 100%;
      right: calc(-100% - 40px);
    }
  }
  
  
.event-name,
.team-word,
.team-value,
.game-time,
.time-to {
    font-family: Rubik !important;
}

.game-all-info{
    position: relative;
    padding-bottom: 8px ;
    padding-top: 8px;
}

.game-all-info > *:not(.header-background-2){
    z-index: 1;
}

.header-background-2{
    position: absolute;
    top:0;
    left:50%;
    bottom:0;
    transform: translateX(-50%);
    width: 100vw;
}

.header-background-2 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.game-info{
    background: #333;
}

.event-name{
    color:#fff;
}

.team-word{
    font-weight: 500 !important;
    color:#000;
}

.team-value{
    font-weight: 500 !important;
    color:#000;
}

.progress{
    font-size: 18px;
    margin-left: 10px ;
    color:#666;
    text-align: right;
}

.game-time-text{
    font-family: Asap;
    font-size: 18px;
    font-weight: 500;
    color:#333;
    line-height: 1;
    margin-bottom: 0px;
}

.game-all-info .game-time-value{
    color:#fff;
}

.game-time-value{
    font-size: 16px;
    font-weight: 400;
}

.game-time-value span{
    font-size: 24px;
    font-weight: 500;
    margin-left: 5px;
    display: inline-block;
}

.teams-done {
    font-family: Asap;
    font-size: 18px;
    line-height: 1em;
}

.teams-done span {
    font-weight: 600;
}

.time-to{
    font-size: 27px;
    font-weight: 300;
}

.game-time-info{
    display: flex;
    justify-content: center;
    width: 400px;
}

.game-time-info .time-to-wrap .time-to {
    font-size: 24px !important;
    font-weight: 600 !important;
}

.team-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.event-info.game-window{
    width: 250px !important;
    white-space: nowrap;
}

.game-stat-info {
    font-family: Asap;
    font-size: 18px;
    min-width: 200px;
}

.game-stat-solved span {
    font-weight: 600;
}

.game-stat-hints {
    margin-right: 5px;
}

.game-stat-hints span {
    font-weight: 600;
}

.game-stat-passed span {
    font-weight: 600;
}

.game-stat-score span {
    font-weight: 600;
}

.game-stat-finish-time {
    font-family: Asap;
    color: #fff;
}

.game-stat-finish-time span {
    font-weight: 600;
}

.game-end {
    font-family: Rubik;
    font-size: 24px;
    font-weight: 600;
}

.game-end-medal {
    min-width: 100px;
}

.game-end-value {
    min-width: 160px;
}

.game-end-text {
    color: #fff;
}

.game-end-place {
    color: #000;
}

.end-popup-logo {
    width: 30%;
    min-width: 300px;
}

.end-popup-title {
    font-family: Rubik;
    font-size: 30px;
    font-weight: 600;
    color: #000;
}

.end-popup-team-name {
    font-family: Rubik;
    font-size: 28px;
    font-weight: 600;
    color: #000;
}

.end-popup-award-medal {
    width: 50%;
    min-width: 280px;
    margin: 0 auto;
}

.end-popup-award-leaves {
    width: 50%;
    margin: 0 auto;
    position: relative;
    height: 100%;
    min-width: 280px;
}

.end-popup-award-place {
    font-family: Rubik;
    font-size: 120px;
    font-weight: 600;
    color: #556cd6;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    height: 75%;
    position: absolute;
    margin: auto;
    top: 0; left: 0; bottom: 0; right: 0;
}

.end-popup-team-place {
    font-family: Rubik;
    font-size: 28px;
    font-weight: 600;
    color: #000;
}

@media(max-width:992px){
    .game-time-info{
        width: 320px;
    }

    .time-to{
        font-size: 22px;
    }
}

@media(max-width:850px){
    .team-info{
        flex-wrap:wrap;
    }

    .event-info.game-window{
        width: 275px !important;
    }

    .team-word,
    .team-value{
        font-size: 21px !important;
    }

    .event-name{
        font-size: 31px !important;
    }
}

@media(max-width:750px){
    .game-all-info{
        margin-top: 0 !important;
        margin-bottom: 40px !important;
    }

    .progress{
        width: 100%;
        text-align: left;
        margin-left: 0 !important;
        margin-top: 2px;
    }

    .time-to{
        font-size: 20px;
    }
}

@media(max-width:675px){
    .game-all-info {
        flex-wrap: wrap;
    }

    .game-all-info .game-time-value {
        color:#fff;
    } 
    
    .game-time-value{
        font-weight: 400;
    }
    
    .game-time-value span{
        font-weight: 500;
        margin-left: 5px;
        display: inline-block;
    }

    .event-name{
        font-size: 30px !important;
    }
    
    .team-word{
        font-size: 21px !important;
    }
    
    .team-value{
        font-size: 21px !important;

    }
    
    .progress{
        font-size: 16px;
    }

    .time-to-wrap{
        min-width: 100%;
    }

    .time-to{
        font-size: 20px;
    }
}

@media(max-width:575px){
    .event-info{
        width: 100%;
    }

    .game-time-info{
        width: 100%;
        justify-content: flex-start;
    }

    .game-all-info{
        flex-wrap: wrap;
    }

    /* .progress{
        width: auto !important;
    } */

    .game-time{
        align-items: flex-start !important;
        margin-top: 18px;
    }

    .game-all-info{
        margin-bottom: 30px !important;
    }

    .team-title{
        margin-right: 20px;
    }

    .time-to-wrap{
        margin-top: 30px;
        margin-bottom: 30px;
    }
}
.waiting-block{
    padding-bottom: 80px;
}

.pre-countdown{
    font-size: 40px !important;
    font-weight: 300 !important;
}

.game-countdown{
    font-size: 20px !important;
    font-weight: 300;
}

.game-countdown > span{
    font-size: 40px !important;
    margin-left: 4px !important;
    font-weight: 400 !important;
}

.waiting-img{
    height: 80px;
    object-fit: contain;
    margin-bottom: 10px;
}

.center-block{
    width: 100px;
}

.big-title{
    font-size: 70px !important;
    line-height: 70px;
    font-weight: 300 !important;
}

.waiting-block {
    font-family: Rubik !important;
}

.waiting-block-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    flex-wrap: wrap;
    border-bottom:none !important;
}

.start-in {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.start-in-text {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 15px;
}

.game-waiting-image {
    width: 50%;
}

.game-waiting-wrapper{
    background-color: rgba(255,255,255,.5);
    padding: 30px;
    border:1px solid #ddd;
}

.game-waiting-content{
    background-color: rgba(255,255,255,.75);
}

.game-waiting-content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 10px 30px;
    font-size: 30px;
}

.waiting-content-header-icon {
    margin-right: 20px;
}

.waiting-content-header-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
}

.waiting-content-header-teams {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
}

.game-waiting-content-body {
    font-weight: 400;
    font-size: 18px;
    padding: 15px 50px;
}

.flip-countdown {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.flip-countdown .flip-countdown-piece {
    margin: 0px 0.8em !important;
}

.flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
    margin: 0 0.025em !important;
    font-size: 50px !important;
}

.flip-countdown .flip-countdown-piece .flip-countdown-title {
    font-size: 20px !important;
    font-family: Rubik !important;
    line-height: 50px;
}

@media(max-width:810px){
    .waiting-block-header {
        flex-direction: column;
        align-items: center;
    }
    
    .waiting-block-header {
        padding-top: 10px;
    }
    
    .waiting-block-header img {
        margin: 20px auto;
    }

    .start-in {
        width: 100%;
    }

    .game-waiting-image {
        width: 100%;
    }

    .game-waiting-content-header {
        font-size: 24px;
    }
}

@media(max-width:700px){
    .big-title{
        font-size: 50px !important;
        line-height: 50px;
    }
}

@media(max-width:600px){
    .game-waiting-content-header {
        flex-direction: column;
        align-items: center;
        padding: 15px 0px;
    }

    .waiting-content-header-time {
        margin-bottom: 15px;
    }
}

@media(max-width:550px){
    .big-title{
        font-size: 40px !important;
        line-height: 40px;
    }
}

@media(max-width:500px){
    .start-info-block{
        display: none !important;
    }

    .wait-countdown.game-countdown{
        font-size: 16px !important;
        font-weight: 300;
    }
    
    .wait-countdown.game-countdown > span{
        font-size: 32px !important;
        margin-left: 4px !important;
        font-weight: 500 !important;
    }
    
}

@media(max-width:420px){
    .wait-countdown.game-countdown{
        font-size: 11px !important;
        font-weight: 300;
    }
    
    .wait-countdown.game-countdown > span{
        font-size: 26px !important;
        margin-left: 4px !important;
        font-weight: 500 !important;
    }
    
}

.start-window{
    background: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    flex-wrap: wrap;
    border-bottom:none !important;
}

.start-title{
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 10px;
    color:#333;
}

.start-text{
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
    color:#666;
}

.start-info{
    width: 50%;
}

.start-img{
    width: 50%;
}

.start-img img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@media(max-width:768px){
    .start-info,
    .start-img{
        width: 100%;
    }
}
.all-puzzles-wrap{
    background-color: rgba(255,255,255,.5);
    padding: 15px;
    padding-bottom: 15px;
    padding-top: 50px;
    border:1px solid #ddd;
    /* border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; */
}

.MuiButton-contained.Mui-disabled{
    background: #ccc !important;
    color: #777 !important;
}

.Mui-disabled .pdf-button{
    color: #777 !important;
}
.finish-img img{
    max-height: 350px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.finish-block{
    background: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
}

.end-title{
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 10px;
    color:#333;
}

.end-text{
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
    color:#666;
}

.rate-icon{
    margin-right: 8px;
}

.rate-line{
    display: flex;
    align-items: center;
}

.rate-line span{
    font-weight: 600;
    display: inline-block;
    margin-left: 3px;
}

.final-wrap{
    display: flex;
    flex-wrap: wrap;
}

.final-info{
    min-width: 300px;
    border-left:1px solid #ddd;
}

.final-text{
    flex:1 1;
}

.team-place{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-top: 45px;
}

.team-place-word{
    font-size: 40px;
    font-weight: 500;
    margin-left: 15px;
    padding-bottom: 5px;
}

.team-place-value{
    font-size: 120px;
    font-weight: 600;
    line-height: 1;
}

@media(max-width:650px){
    .final-info{
        width: 100%;
        border-left: none;
        border-top:1px solid #ddd;
    }
}
.fade-enter {
    opacity: 0;
    transform: translateX(-100%);
}

.fade-enter-active {
    opacity: 1;
    transform: translateX(0%);
}

.fade-exit {
    opacity: 0;
    transform: translateX(0%);
}

.fade-exit-active {
    opacity: 0;
    transform: translateX(100%);
}

.fade-enter-active {
    transition: opacity 1300ms, transform 1300ms;
}

.fade-exit-active {
    transition: opacity 300ms, transform 300ms;
}

.pdf-button {
    color: #fff;
}

.Mui-disabled .pdf-button {
    color: rgba(0, 0, 0, 0.26);
}


.MuiInputBase-input[name="answer"] {
    height: 42px !important;
    padding: 0 15px !important;
    text-align: left !important;
    background: #fff;
    border-radius: 5px;
}

.MuiButton-containedSizeLarge[data-id="answer"] {
    height: 42px !important;
}

.MuiInputBase-input::-webkit-input-placeholder {
    text-align: left !important;
}

.MuiInputBase-input:-ms-input-placeholder {
    text-align: left !important;
}

.MuiInputBase-input::-ms-input-placeholder {
    text-align: left !important;
}

.MuiInputBase-input::placeholder {
    text-align: left !important;
}

.pdf-icon {
    margin-top: -3px;
    margin-left: 5px;
}

.pdf-button {
    display: flex;
    align-items: center;
}

.pdf-btn a {
    width: 100%;
    height: 100%;
    display: inline-block;
}

.puzzle-title {
    font-size: 22px;
    font-weight: 500;
}

.puzzle-title span {
    font-size: 32px;
    font-weight: 300;
    display: inline-block;
    margin-left: 4px;
}

.puzzle-result {
    font-size: 35px;
    font-weight: 400;
    line-height: 1;
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.puzzle-block {
    height: 42px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #666;
    -ms-user-select: none;
    -webkit-user-select: none;
            user-select: none;
}

.puzzle-content {
    display: flex;
    /* flex-wrap: wrap; */
    width: 100%;
}

.puzzle-interact {
    flex: 1 1;
}

.puzzle-load {
    padding-right: 40px;
}

.puzzle-content .MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error.MuiFormHelperText-filled {
    font-size: 15px !important;
}

.puzzle-stat {
    position: relative;
}

.puzzle-tick {
    position: absolute;
    left: 280px;
}

@media (max-width: 700px) {
    .puzzle-content {
        flex-wrap: wrap;
    }

    .puzzle-load {
        padding-right: 0;
        width: 100%;
        margin-bottom: 15px;
    }

    .puzzle-tick {
        position: absolute;
        left: 250px;
        top: -70px;
    }

    .puzzle-result {
        max-width: unset;
    }

}

@media (max-width: 510px) {
    .puzzle-block {
        display: none;
    }
}

.hint-status {
    font-size: 22px;
    font-weight: 500;
    color: #333;
}

.hint-text {
    font-size: 17px;
    font-weight: 400;
    color: #666;
}
.hint-timer{
    font-size: 16px;
    color:#666;
}

.hint-btn{
    padding: 4px 42px !important;
}


@media(max-width:625px){
    .hint-timer{
        width: 100%;
        margin-top: 10px;
    }
}

.hint-timer{
    font-size: 16px;
    color:#666;
}

.hint-btn{
    padding: 4px 42px !important;
}


@media(max-width:625px){
    .hint-timer{
        width: 100%;
        margin-top: 10px;
    }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fillGreen {
    100% {
        box-shadow: inset 0px 0px 0px 30px #7ac142;
    }
}

@keyframes fillOrange {
    100% {
        box-shadow: inset 0px 0px 0px 30px #f9b134;
    }
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__circle-green {
    stroke: #7ac142;
}

.checkmark__circle-orange {
    stroke: #f9b134;
}

.checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
}

.checkmark-green {
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fillGreen .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark-orange {
    box-shadow: inset 0px 0px 0px #f9b134;
    animation: fillOrange .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.connection-test-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.connection-test-block {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.test-block-name {
    margin-right: 20px !important;
}

.connection-test-result {
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* -----------------------------------------------------
--------------------------Fonts-------------------------
----------------------------------------------------- */

/* -----------------------------------------------------
--------------------------Global------------------------
----------------------------------------------------- */
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: #000;
    display: inline-block;
}

a,
button,
input,
textarea {
    border: none;
    box-shadow: none;
    border-radius: 0;
    -webkit-appearance: none;
    outline: none;
}

html,
body{
    overflow-x: hidden;
    padding-right: 0px !important;
}

body {
    color: #232323;
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    position: relative !important; /* Game vertical scroll fix */
    min-height: 100vh !important;
    background-color: #fff  !important;
}

#root{
    min-height: 100vh;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

.wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
}

.container {
    max-width: 1200px;
    width: 100%;
    padding: 0 25px;
    margin: 0 auto;
    position: relative;
}

.flex-wrap {
    display: flex;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}









/* -----------------------------------------------------
------------------------Template------------------------
----------------------------------------------------- */
.btn {
    border-radius: 5px;
    background-color: #ccc;
    padding: 15px;
    text-align: center;
    cursor: pointer;
    transition: .3s;
    width: 100%;
    color: #ffffff;
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
    box-shadow: 0 3px 6px rgba(237, 28, 36, 0.45);
}

@media (max-width:768px) {
    .btn {
        padding: 15px;
    }
}

.btn-red {
    box-shadow: 0 0 6px #ed1c24;
    background-color: #ed1c24;
}

.btn-gray {
    box-shadow: 0 3px 6px rgba(237, 28, 36, 0.45);
    background-color: #3b3b3b;
}

.btn:hover {
    box-shadow: 0 7px 14px rgba(0, 0, 0, .3);
}

.btn-red:hover {
    box-shadow: 0 2px 12px #ed1c24;
}

.btn-gray:hover {
    box-shadow: 0 7px 9px rgba(237, 28, 36, 0.45);
}

.btn-big-round {
    border-radius: 25px;
}

.btn-small-round {
    border-radius: 20px;
}


.simple-input {
    width: 100%;
    max-width: 100%;
    position: relative;
}

input,
textarea {
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: none;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 25px;
    padding: 17px;
    color: #313131;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.32px;
    text-align: center;
    font-weight: 700;
}

textarea {
    resize: none;
    height: 130px;
}

textarea:focus,
input:focus {
    border-color: none;
}

textarea::-webkit-input-placeholder, input::-webkit-input-placeholder {
    color: #313131;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.32px;
    text-align: center;
    font-weight: 700;
    -webkit-transition: .3s;
    transition: .3s;
}

textarea:-ms-input-placeholder, input:-ms-input-placeholder {
    color: #313131;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.32px;
    text-align: center;
    font-weight: 700;
    -ms-transition: .3s;
    transition: .3s;
}

textarea::-ms-input-placeholder, input::-ms-input-placeholder {
    color: #313131;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.32px;
    text-align: center;
    font-weight: 700;
    -ms-transition: .3s;
    transition: .3s;
}

textarea::placeholder,
input::placeholder {
    color: #313131;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.32px;
    text-align: center;
    font-weight: 700;
    transition: .3s;
}

input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder {
    opacity: 0;
}

input:focus:-ms-input-placeholder, textarea:focus:-ms-input-placeholder {
    opacity: 0;
}

input:focus::-ms-input-placeholder, textarea:focus::-ms-input-placeholder {
    opacity: 0;
}

input:focus::placeholder,
textarea:focus::placeholder {
    opacity: 0;
}

.red-text {
    color: #ed1c24;
}

.gray-bg {
    background-color: #f8f8fa;
    position: relative;
}

.with-offset {
    /* margin-bottom: -25px; */
    padding-bottom: 50px;
}

.big-title {
    opacity: 0.84;
    color: #000000;
    font-size: 38px;
    font-weight: 700;
    line-height: 1.3;
}

@media (max-width:992px) {
    .big-title {
        font-size: 30px;
    }

    .with-offset {
        margin-bottom: 0;
        padding-bottom: 20px;
    }
}

@media (max-width:600px) {
    .big-title {
        font-size: 24px;
        font-weight: 900;
    }
}

.center {
    text-align: center;
}











/* -----------------------------------------------------
--------------------------Start-------------------------
----------------------------------------------------- */





  .Toastify__toast {
    padding-left: 20px !important;
  }

  .Toastify__toast--success {
    background: #333 !important;
  }

  .Toastify__toast--error {
    background: #e7717d !important;
  }

  .Toastify__toast-body {
    font-size: 18px;
    font-weight: 400 !important;
    color: #fff;
  }

  .Toastify__close-button {
    margin-top: -5px;
    margin-right: -1px;
    opacity: 1 !important;
    display: none !important
  }

  .Toastify__progress-bar {
    background: #fff !important;
    height: 3px !important;
  }




/* -----------------------------------------------------
---------------------------End--------------------------
----------------------------------------------------- */

