/* -----------------------------------------------------
--------------------------Fonts-------------------------
----------------------------------------------------- */

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Asap:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alatsi&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Girassol&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aleo&display=swap');

/* -----------------------------------------------------
--------------------------Global------------------------
----------------------------------------------------- */
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: #000;
    display: inline-block;
}

a,
button,
input,
textarea {
    border: none;
    box-shadow: none;
    border-radius: 0;
    -webkit-appearance: none;
    outline: none;
}

html,
body{
    overflow-x: hidden;
    padding-right: 0px !important;
}

body {
    color: #232323;
    font-size: 14px;
    font-family: Roboto;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    position: relative !important; /* Game vertical scroll fix */
    min-height: 100vh !important;
    background-color: #fff  !important;
}

#root{
    min-height: 100vh;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

.wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
}

.container {
    max-width: 1200px;
    width: 100%;
    padding: 0 25px;
    margin: 0 auto;
    position: relative;
}

.flex-wrap {
    display: flex;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}









/* -----------------------------------------------------
------------------------Template------------------------
----------------------------------------------------- */
.btn {
    border-radius: 5px;
    background-color: #ccc;
    padding: 15px;
    text-align: center;
    cursor: pointer;
    transition: .3s;
    width: 100%;
    color: #ffffff;
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
    box-shadow: 0 3px 6px rgba(237, 28, 36, 0.45);
}

@media (max-width:768px) {
    .btn {
        padding: 15px;
    }
}

.btn-red {
    box-shadow: 0 0 6px #ed1c24;
    background-color: #ed1c24;
}

.btn-gray {
    box-shadow: 0 3px 6px rgba(237, 28, 36, 0.45);
    background-color: #3b3b3b;
}

.btn:hover {
    box-shadow: 0 7px 14px rgba(0, 0, 0, .3);
}

.btn-red:hover {
    box-shadow: 0 2px 12px #ed1c24;
}

.btn-gray:hover {
    box-shadow: 0 7px 9px rgba(237, 28, 36, 0.45);
}

.btn-big-round {
    border-radius: 25px;
}

.btn-small-round {
    border-radius: 20px;
}


.simple-input {
    width: 100%;
    max-width: 100%;
    position: relative;
}

input,
textarea {
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: none;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 25px;
    padding: 17px;
    color: #313131;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.32px;
    text-align: center;
    font-weight: 700;
}

textarea {
    resize: none;
    height: 130px;
}

textarea:focus,
input:focus {
    border-color: none;
}

textarea::placeholder,
input::placeholder {
    color: #313131;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.32px;
    text-align: center;
    font-weight: 700;
    transition: .3s;
}

input:focus::placeholder,
textarea:focus::placeholder {
    opacity: 0;
}

.red-text {
    color: #ed1c24;
}

.gray-bg {
    background-color: #f8f8fa;
    position: relative;
}

.with-offset {
    /* margin-bottom: -25px; */
    padding-bottom: 50px;
}

.big-title {
    opacity: 0.84;
    color: #000000;
    font-size: 38px;
    font-weight: 700;
    line-height: 1.3;
}

@media (max-width:992px) {
    .big-title {
        font-size: 30px;
    }

    .with-offset {
        margin-bottom: 0;
        padding-bottom: 20px;
    }
}

@media (max-width:600px) {
    .big-title {
        font-size: 24px;
        font-weight: 900;
    }
}

.center {
    text-align: center;
}











/* -----------------------------------------------------
--------------------------Start-------------------------
----------------------------------------------------- */





  .Toastify__toast {
    padding-left: 20px !important;
  }

  .Toastify__toast--success {
    background: #333 !important;
  }

  .Toastify__toast--error {
    background: #e7717d !important;
  }

  .Toastify__toast-body {
    font-size: 18px;
    font-weight: 400 !important;
    color: #fff;
  }

  .Toastify__close-button {
    margin-top: -5px;
    margin-right: -1px;
    opacity: 1 !important;
    display: none !important
  }

  .Toastify__progress-bar {
    background: #fff !important;
    height: 3px !important;
  }




/* -----------------------------------------------------
---------------------------End--------------------------
----------------------------------------------------- */
